import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import {  VueMetaApp } from 'vue-meta';
import Vuelidate from 'vuelidate';
import VueGtag from "vue-gtag-next";


const app = createApp(App);
app.config.productionTip = false;
app.use(router);
app.use(VueMetaApp);
app.use(Vuelidate);

app.use(VueGtag, {
  property: {
    id: 'G-9LZKQ56R0B',
    params:{
      send_page_view: true,
    }
  },
});

/**
 * Global Vue Attributes
 */
 app.config.globalProperties.$toEmailAddress = 'info@badal.io';
 app.config.globalProperties.$fromEmailAddress = 'info@badal.io';
 app.config.globalProperties.$careerFormPostUrl = '';
 app.config.globalProperties.$contactFormPostUrl = 'https://send-email-1svjor22.uc.gateway.dev/send-email';

 app.config.globalProperties.$blogUrl =
  'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2Fbadal-io';

  app.config.globalProperties.$jobsUrl =
  'https://api.lever.co/v0/postings/badal';

  app.config.globalProperties.$linkedInURL = 'http://www.linkedin.com/company/64523298';
  app.config.globalProperties.$youtubeURL = '';
  app.config.globalProperties.$facebookURL = '';
  app.config.globalProperties.$twitterURL = '';
  app.config.globalProperties.$privacyURL = 'https://www.telus.com/en/about/privacy/business-customer-privacy-policy';



  app.mount('#app');

/**
 * Filters
 */
 app.config.globalProperties.$filters = {
  striphtml: (value) => {
    const div = document.createElement('div');
    div.innerHTML = value;
    const text = div.textContent || div.innerText || '';
    return text;
  },
};
